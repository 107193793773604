import React from 'react'
import Autoplay from 'embla-carousel-autoplay'
import { sbEditable } from "@storyblok/storyblok-editable";
import EmblaCarousel from '../lib/Carousel/EmblaCarousel';
import { mergeClasses } from '../lib/_helper';

export default function Carousel({ blok, shared }) {
  if (blok?.slides)
  {
    const options = { loop: true }
    let autoplay = null;
    
    const plugins = []
    if (blok.autoplay) {
      autoplay = Autoplay({ delay: blok?.autoplayDelay ? (blok.autoplayDelay * 1000) : 5000 })
      plugins.push(autoplay)
    }

    return (
      <div
        className={mergeClasses(
          blok.hideMobileView ? "hidden sm:block" : "",
          blok.hideDesktopView ? "block sm:hidden" : "",
          blok.backgroundColour ? "bg-" + blok.backgroundColour : "bg-white"
        )}
        {...sbEditable(blok)}
      >
        <EmblaCarousel slides={blok?.slides} options={options} plugins={plugins} shared={shared}/>
      </div>
    )
  }
  return;
}
