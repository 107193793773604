import React from "react";
import Link from "next/link";

import { absoluteUrl } from "../../lib/_helper";

const CareerListingApplyButton = ({careerId}) => {
  return (
    <Link
      href={absoluteUrl(`/about/careers/application?id=${careerId}`)}
      className="align-middle"
    >
      <button
        className="rounded-full bg-cto_primary_orange w-full px-6 py-4 text-md text-white shadow-sm hover:opacity-80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cto_primary_orange"
      >
        Apply
      </button>
    </Link>
  );
};

export default CareerListingApplyButton;
