import React, { useRef, useState } from "react";
import { UploadIcon, XIcon, TrashIcon } from '@heroicons/react/outline'
import { mergeClasses } from "../_helper";

const BITS_IN_ONE_MEGABYTE = 1048576

const convertNestedObjectToArray = (nestedObj) =>
  Object.keys(nestedObj).map((key) => nestedObj[key]);

const FileUpload = ({
  label,
  updateFilesCb = () => console.error("No update file callback was provided."),
  maxFileSizeInMegaBytes = 2, //2MB Default Max File Size.
  disabled,
  ...otherProps
}) => {
  const fileInputField = useRef(null);
  const [files, setFiles] = useState({});

  const handleUploadBtnClick = () => {
    fileInputField.current.click();
  };

  const addNewFiles = (newFiles) => {
    for (let file of newFiles) {
      if (file.size <= (maxFileSizeInMegaBytes * BITS_IN_ONE_MEGABYTE)) {
        if (!otherProps.multiple) {
          return { file };
        }
        files[file.name] = file;
      }
    }
    return { ...files };
  };

  const callUpdateFilesCb = (files) => {
    const filesAsArray = convertNestedObjectToArray(files);
    updateFilesCb(filesAsArray);
  };

  const handleNewFileUpload = (e) => {
    const { files: newFiles } = e.target;
    if (newFiles.length) {
      let updatedFiles = addNewFiles(newFiles);
      setFiles(updatedFiles);
      callUpdateFilesCb(updatedFiles);
    }
  };

  const removeFile = (fileName) => {
    delete files[fileName];
    setFiles({ ...files });
    callUpdateFilesCb({ ...files });
  };

  const removeAllFiles = () => {
    setFiles({});
    callUpdateFilesCb({});
  };

  const hasNoFiles = Object.keys(files).length === 0;

  let errorMessage = null

  return (
    <fieldset disabled={disabled} className="disabled:bg-cto_secondary_dark_grey/10 disabled:cursor-not-allowed disabled:pointer-events-none">
      {hasNoFiles && 
        <div className="relative block group w-full border-2 border-gray-300 border-dashed rounded-lg p-6 text-center hover:border-cto_primary_purple focus:outline-none">
          <svg
            className="mx-auto h-12 w-12 text-gray-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              vectorEffect="non-scaling-stroke"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
            />
          </svg>
          <h3 className="mt-2 text-sm font-medium text-gray-900">No file uploaded...</h3>
          <p className="mt-1 text-sm text-gray-500">Drop files here, or click to browse...</p>
          <p className="mt-1 text-sm text-gray-500">Maximum file size: {maxFileSizeInMegaBytes}MB</p>
          <div className="mt-4">
            <button
              type="button"
              disabled={disabled}
              className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-full text-white bg-cto_primary_orange focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cto_primary_purple group-hover:bg-cto_primary_purple group-hover:shadow-xl disabled:bg-cto_secondary_dark_grey/80 disabled:cursor-not-allowed"
              onClick={handleUploadBtnClick}
            >
              <UploadIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true"/>
              Upload CV
            </button>
          </div>
          <input
            className="block text-lg w-full absolute top-0 left-0 right-0 bottom-0 opacity-0 focus:outline-none cursor-pointer disabled:bg-cto_secondary_dark_grey/10 disabled:cursor-not-allowed"
            style={{ textTransform: "none", border: "none"}}
            type="file"
            ref={fileInputField}
            onChange={handleNewFileUpload}
            title=""
            value=""
            tabIndex={-1}
            {...otherProps}
          />
        </div>
      }
      {!hasNoFiles && 
        <div className="relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-6 text-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          <svg
            className="mx-auto h-12 w-12 text-gray-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              vectorEffect="non-scaling-stroke"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
            />
          </svg>
          {Object.keys(files).map((fileName, index) => {
            let file = files[fileName];
            const sizeInMB = (file.size/BITS_IN_ONE_MEGABYTE).toFixed(2)
            return (
              <div key={fileName} className="flex items-center justify-center">
                <h3 className="text-sm font-medium text-gray-900 h-full my-allowMultipleUploads">{file.name} - {sizeInMB} MB</h3>
                <i className="cursor-pointer hover:opacity-50 h-4 my-auto pl-3"><XIcon className="h-4 w-4 my-auto" aria-hidden="true" onClick={() => !disabled && removeFile(fileName)}/></i>
              </div>
            );
          })}
          <div className="mt-4">
            <button
              type="button"
              className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-full text-white bg-cto_primary_orange focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cto_primary_purple hover:bg-cto_primary_purple hover:shadow-xl disabled:bg-cto_secondary_dark_grey/80 disabled:cursor-not-allowed"
              onClick={removeAllFiles}
              disabled={disabled}
            >
              <TrashIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
              Remove
            </button>
          </div>
        </div>
      }
    </fieldset>
  );
};

export default FileUpload;