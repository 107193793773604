import EmailValidator from "email-validator";

export const PropertyExists = (str) => str?.length > 0 ?? false;

export const mergeClasses = (...classes) => classes.filter(Boolean).join(' ')

export const absoluteUrl = (url) => {
  const isEmptyUrl = !url || url == null || url == undefined || url == "" || url == "#"; // Return "#"
  const isAbsoluteUrl = !isEmptyUrl && (url.startsWith("http://") || url.startsWith("https://")); // Return original url
  
  if (isEmptyUrl) {
    // console.log(`[1] was ${url}, now #`)
    return '#';
  }
  else if (isAbsoluteUrl){
    // console.log(`[2] was ${url}, now ${url}`)
    return url;
  }
  else { // Must be a relative url.
    const relativeUrl = url.startsWith("/") ? url : "/" + url ;
    const newUrl = GetRootDomainWithProtocol() + relativeUrl;
    // console.log(`[3] was ${url}, now ${newUrl}`)
    return newUrl;
  }
}

export const GetRootDomainWithProtocol = () => process.env.NEXT_PUBLIC_HTTP + process.env.NEXT_PUBLIC_HOST;

export const removePrefixIfExists = (string, prefix) => {
  if (string.startsWith(prefix)) {
    return string.slice(prefix.length);
  }
  return string;
};

export const removeSuffixIfExists = (string, suffix) => {
  if (string.endsWith(suffix)) {
    return string.slice(0, -suffix.length);
  }
  return string;
};

export const validateEmailAddress = (emailAddress) => {
  if (!EmailValidator.validate(emailAddress)) {
    throw new Error('Invalid email');
  }
  if (!emailAddress) {
    throw new Error('Email was not found');
  }
};

export const isWithinCharacterLimit = (text, limit) => {
  // Validate inputs
  if (typeof limit !== "number" || limit < 0) {
      throw new Error("The 'limit' parameter must be a non-negative number.");
  }

  // Handle undefined or null text
  if (text === undefined || text === null) {
      return true;
  }

  // Check the length of the string
  if (typeof text !== "string") {
      throw new Error("The 'text' parameter must be a string, null, or undefined.");
  }

  return text.length <= limit;
}