import Teaser from "./Teaser";
import Grid from "./Grid";
import Feature from "./Feature";
import Page from "./Page";
import ColumnCards from "./blog-sections/ColumnCards";
import SimpleJustified from "./cta-sections/SimpleJustified";
import FullWidthVerticleImages from "./team/FullWidthVerticleImages";
import HorizontalProfileCard from "./team/HorizontalProfileCard";
import Profile from "./team/Profile";
import RequestCallback from "./cta-sections/RequestCallback";
import SimpleCenteredPanel from "./cta-sections/SimpleCenteredPanel";
import Faq from "./Faq";
import Faqs from "./Faqs";
import SignUpEmail from "./cta-sections/SignUpEmail";
import AlternatingWithOptionalTestimonial from "./AlternatingWithOptionalTestimonial";
import HorizontalContainer from "./HorizontalContainer";
import ContainerHeader from "./container-panels/ContainerHeader";
import ContainerIconPoint from "./container-panels/ContainerIconPoint";
import ContainerButton from "./container-panels/ContainerButton";
import ContainerPill from "./container-panels/ContainerPill";
import ContainerImage from "./container-panels/ContainerImage";
import ContainerMaskedImage from "./container-panels/ContainerMaskedImage";
import MaskedCurve from "./container-panels/MaskedCurve";
import ProfileContainer from "./team/ProfileContainer";
import ProfileComponent from "./team/ProfileComponent";
import LogoStrip from "./LogoStrip";
import Testimonial from "./Testimonial";
import Stats from "./Stats";
import Contact from "./Contact";
import NavCard from "./Navigation/NavCard";
import PageHeader from "./PageHeader";
import ArticleContent from "./blog-sections/ArticleContent";
import HomeHero from "./HomeHero";
import HomeHeroCard from "./HomeHeroCard";
import HomeHeroCardContainer from "./HomeHeroCardContainer";
import Carousel from "./Carousel";
import CarouselFree from "./CarouselFree";
import ServiceCardPanel from "./ServiceCardPanel";
import ServiceCard from "./ServiceCard";
import ServiceHero from "./ServiceHero";
import SixPanelSectionCTA from "./SixPanelSectionCTA";
import SectionHero from "./SectionHero";
import SubSectionHero from "./SubSectionHero";
import ListAllCareers from "./career-section/ListAllCareers";
import CircleImageWithText from "./CircleImageWithText";
import AbstractFloatingBackgroundShape from "./AbstractFloatingBackgroundShape";
import CenteredCopy from "./CenteredCopy";
import ValuesCard from "./ValuesCard";
import ValuesCardPanel from "./ValuesCardPanel";
import CircleIconWithText from "./CircleIconWithText";
import TextAndSmallImageSideBySide from "./TextAndSmallImageSideBySide";
import CareersHero from "./CareersHero";
import Gallery from "./Gallery";
import TypeformQuestionnaire from "./TypeformQuestionnaire";
import SplitContainer from "./SplitContainer";
import BigTitleText from "./BigTitleText";
import ContactMini from "./ContactMini";
import ImagePanel from "./ImagePanel";
import SemiCircleWithOffsetImage from './SemiCircleWithOffsetImage';
import GatedContent from './GatedContent';
import GatedContentScrollTo from './GatedContentScrollTo';
import Redirect from "./Redirect";
import RegisterForEvent from "./RegisterForEvent";
import VideoEmbed from "./VideoEmbed";
import Survey from "./Survey";
import NameGen from "./NameGen";
import FloatingBanner from "./FloatingBanner";
import FloatingBannerText from "./FloatingBannerText";
import CareerApplication from "./career-section/CareerApplication";
import CareerListing from "./career-section/CareerListing";

// resolve Storyblok components to Next.js components
const Components = {
  redirect: Redirect,
  teaser: Teaser,
  grid: Grid,
  feature: Feature,
  page: Page,
  columnCards: ColumnCards,
  simpleJustified: SimpleJustified,
  fullWidthVerticleImages: FullWidthVerticleImages,
  horizontalProfileCard: HorizontalProfileCard,
  horizontalContainer: HorizontalContainer,
  careersHero: CareersHero,
  carousel: Carousel,
  carouselFree: CarouselFree,
  containerHeader: ContainerHeader,
  containerButton: ContainerButton,
  containerIconPoint: ContainerIconPoint,
  containerPill: ContainerPill,
  containerImage: ContainerImage,
  containerMaskedImage: ContainerMaskedImage,
  gallery: Gallery,
  maskedCurve: MaskedCurve,
  profile: Profile,
  profileContainer: ProfileContainer,
  profileComponent: ProfileComponent,
  requestCallback: RequestCallback,
  signUpEmail: SignUpEmail,
  simpleCenteredPanel: SimpleCenteredPanel,
  listAllCareers: ListAllCareers,
  faq: Faq,
  faqs: Faqs,
  alternatingWithOptionalTestimonial: AlternatingWithOptionalTestimonial,
  logoStrip: LogoStrip,
  testimonial: Testimonial,
  stats: Stats,
  contact: Contact,
  contactMini: ContactMini,
  navCard: NavCard,
  pageHeader: PageHeader,
  articleContent: ArticleContent,
  homeHero: HomeHero,
  homeHeroCard: HomeHeroCard,
  homeHeroCardContainer: HomeHeroCardContainer,
  serviceCardPanel: ServiceCardPanel,
  serviceCard: ServiceCard,
  serviceHero: ServiceHero,
  sixPanelSectionCTA: SixPanelSectionCTA,
  sectionHero: SectionHero,
  circleImageWithText: CircleImageWithText,
  circleIconWithText: CircleIconWithText,
  abstractFloatingBackgroundShape: AbstractFloatingBackgroundShape,
  subSectionHero: SubSectionHero,
  centeredCopy: CenteredCopy,
  textAndSmallImageSideBySide: TextAndSmallImageSideBySide,
  valuesCard: ValuesCard,
  valuesCardPanel: ValuesCardPanel,
  typeformQuestionnaire: TypeformQuestionnaire,
  splitContainer: SplitContainer,
  bigTitleText: BigTitleText,
  imagePanel: ImagePanel,
  semiCircleWithOffsetImage: SemiCircleWithOffsetImage,
  gatedContent: GatedContent,
  gatedContentScrollTo: GatedContentScrollTo,
  registerForEvent: RegisterForEvent,
  videoEmbed: VideoEmbed,
  survey: Survey,
  nameGen: NameGen,
  floatingBanner: FloatingBanner,
  floatingBannerText: FloatingBannerText,
  careerListing: CareerListing,
};

const DynamicComponent = ({ blok, shared }) => {
  // check if component is defined above
  if (typeof Components[blok.component] !== "undefined") {
    const Component = Components[blok.component];

    return <Component blok={blok} key={blok._uid} shared={shared} />;
  }

  // fallback if the component doesn't exist
  return (
    <p>
      The component <strong>{blok.component}</strong> has not been created yet.
    </p>
  );
};

export default DynamicComponent;
