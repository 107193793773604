import React from "react";
import { sbEditable } from "@storyblok/storyblok-editable";

import { ResolveRichText } from "../../lib/storyblok";

const CareerListingDescription = ({sections}) => {
  sections = sections ?? []
  
  return (
    <div className="text-cto_secondary_dark_grey">
      {sections.map((section) => {
        switch (section.component) {
          case "careerListingDescriptionHeading":
            return <div {...sbEditable(section)} className="font-montserrat font-bold text-3xl py-6">{section.heading}</div>

          case "careerListingDescriptionSubHeading":
            return <div {...sbEditable(section)} className="font-bold text-xl text-cto_primary_orange pt-6 pb-1">{section.subHeading}</div>

          case "careerListingDescriptionBody":
            return (
              <div {...sbEditable(section)} className="pb-6">
                <span
                  className="text-lg/7 opacity-80"
                  dangerouslySetInnerHTML={{ __html: ResolveRichText.render(section.text) }}
                />
              </div>
            )

          default:
            return <></>
        }
      })}
    </div>
  );
};

export default CareerListingDescription;
