import React, {
  Children,
  isValidElement,
  cloneElement,
  useState,
  useEffect
} from "react";
import Head from "next/head";

import RequestCallback from "./cta-sections/RequestCallback";
import Navigation from "./Navigation/Navigation";
import Footer from "../components/Footer";
import Breadcrumbs from "../components/Breadcrumbs";
import Animation from "../components/Animation";
import { useStoryblok } from "../lib/storyblok";
import { userDeviceIsMobile } from "../lib/device";
import { createCookie, readCookie } from "../lib/cookie";
import { useIsomorphicLayoutEffect } from "../lib/useIsomorphicLayoutEffect";
import { PropertyExists } from "../lib/_helper";

const Layout = ({
  children,
  header,
  story,
  preview,
  splittedSlug,
  isHomePage,
}) => {
  const enableBridge = true; // load the storyblok bridge everywhere
  // const enableBridge = preview; // enable bridge only in prevew mode
  story = useStoryblok(story, enableBridge);

  const isProfile = story.content.component == "profile";

  /* Plays the opening animation once per certain period of time. (instead of on every single load) */
  const [shouldPlayAnimation, setShouldPlayAnimation] = useState(false);
  useEffect(() => {
    const cookieName = "hasPlayedAnimation";

    let value = false;
    if (isHomePage && !userDeviceIsMobile()) {
      const cookie = readCookie(cookieName);
      if (cookie == null) {
        value = true;
        createCookie(cookieName, 1, 30);
      }
    }

    setShouldPlayAnimation(value);
  }, [isHomePage]);

  /* Scrolls past the breadcrumbs on mobile view to save precious vertical space */
  useIsomorphicLayoutEffect(() => {
    if (userDeviceIsMobile() && !isHomePage) {
      document.documentElement.scrollTop = 100;
    }
  });

  /* Injecting the story content reference such that storyblok changes will be updated in realtime */
  const childrenWithProps = Children.map(children, (child) => {
    // Checking isValidElement is the safe way and avoids a TS error too.
    if (isValidElement(child)) {
      return cloneElement(child, { blok: { id: story.id, ...story.content } });
    }

    return child;
  });



  const _title = story?.content?.seoMetatags?.title
  const title = PropertyExists(_title) 
    ? _title
    : "CTO Labs"

  const _description = story?.content?.seoMetatags?.description
  const description = PropertyExists(_description) 
    ? _description
    : "Better begins here. CTO Labs makes complex tech clear. Savvy M&A tech advice and insightful tech services from strategy to execution."

  return (
    <div className="bg-white font-sans">
      <Head>
        <title>{title}</title>
        <meta
          name="description"
          content={description}
        />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png"></link>
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png"></link>
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png"></link>
        <link rel="manifest" href="/site.webmanifest"></link>
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5"></link>
        <meta name="msapplication-TileColor" content="#da532c"></meta>
        <meta name="theme-color" content="#ffffff"></meta>
        <link rel="preload" href="/masks/C-mask-180.svg" as="image"></link>
        <link rel="preload" href="/masks/C-mask.svg" as="image"></link>
        <link rel="preload" href="/masks/D-curve-mask.svg" as="image"></link>
        <link rel="preload" href="/masks/Square-mask.svg" as="image"></link>
        <link rel="preload" href="/masks/T-mask.svg" as="image"></link>
      </Head>
      <header className="sticky top-0 z-50">
        {shouldPlayAnimation && <Animation />}
        <Navigation blok={header.content} />
      </header>
      <main className="relative overflow-x-hidden">
        {!(isHomePage || isProfile) && (
          <Breadcrumbs
            splittedSlug={splittedSlug}
            backgroundColour={story?.content?.breadcrumbBackgroundColour}
          />
        )}
        <div>{childrenWithProps}</div>
        {isProfile && <RequestCallback />}
      </main>
      <footer>
        <Footer blok={header.content} />
      </footer>
    </div>
  );
};

export default Layout;
