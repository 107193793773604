import React, { useState, useEffect } from "react";
import Router from 'next/router';
import Link from "next/link";
import EmailValidator from "email-validator";
import swal from 'sweetalert';
import { ChevronRightIcon } from "@heroicons/react/solid";
import { PhoneIcon, MailIcon, GlobeIcon, IdentificationIcon, LocationMarkerIcon, DocumentTextIcon, UserCircleIcon } from "@heroicons/react/outline";

import FileUploadComponent from "../../lib/Contact/FileUploadComponent";
import { absoluteUrl } from "../../lib/_helper";

const CareerApplication = ({career}) => {
  const statesOfAustralia = ["NSW","VIC","QLD","SA","WA","TAS","ACT","NT"];

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [hasAustralianWorkingRights, setHasAustralianWorkingRights] = useState(false);
  const [country, setCountry] = useState("");
  const [stateOfResidence, setStateOfResidence] = useState(statesOfAustralia[0]);
  const [cityOfResidence, setCityOfResidence] = useState("");
  const [uploadedFile, setUploadedFile] = useState();
  const [personalSummary, setPersonalSummary] = useState("");
  const [disabled, setDisabled] = useState(false);

  const CareerSummary = {
    id: career.id,
    href: career.full_slug,
    companyName: "CTO Labs",
    JobTitle: career.content.JobTitle,
    JobSummary: career.content.JobSummary,
    JobLocation: career.content.JobLocation.join(", "),
    JobDepartment: career.content.JobDepartment,
    JobEmploymentType: career.content.JobEmploymentType,
    JobWorkStyle: career.content.JobWorkStyle,
  };

  useEffect(() => {document.getElementById("careerApplicationFields").disabled = disabled}, [disabled])

  const submitApplication = async (event) => { 
    event.preventDefault();
    // if (disabled) return;

    const target = event?.target;
    const email = target?.emailAddress?.value;
    
    // Basic email validation check
    if (!EmailValidator.validate(email)) {
      swal("Oops!", "Invalid Email Format", "error")
      return;
    }

    if (!uploadedFile) {
      swal("Oops!", "No file was uploaded", "error")
    }

    // setDisabled(true);

    const formData = new FormData();
    formData.append("careerId", career.id);
    formData.append("firstName", firstName);
    formData.append("lastName", lastName);
    formData.append("emailAddress", emailAddress);
    formData.append("phoneNumber", phoneNumber)
    
    formData.append("hasAustralianWorkingRights", hasAustralianWorkingRights);
    formData.append("country", hasAustralianWorkingRights ? "Australia" : country);

    if (hasAustralianWorkingRights) {
      formData.append("state", stateOfResidence);
      formData.append("city", cityOfResidence);
    }

    formData.append("file", uploadedFile)
    formData.append("personalSummary", personalSummary);


    fetch(absoluteUrl("/api/career-application"), {
      body: formData,
      method: "POST",
    }).then((response) => {
      if (response.status == 500) {
        console.log("throwing error...")
        throw Error(response.statusText);
      }

      swal({
        title: "Thanks for your interest, we'll be in touch very soon.",
        icon: "success",
        button: "Done",
        closeOnClickOutside: false,
      }).then(() => {
        // Router.replace("/about/careers")
      });
    })
    .catch((error) => {
      console.error({error})
      swal("Oops, something went wrong!", "If you're still interested in the applying, please send an email to connect@ctolabs.com.au", "error")
    });
  };

  return (
    <div className="bg-cto_secondary_light_warm_grey flex text-cto_secondary_dark_grey">
      <div className="max-w-3xl w-full mx-auto flex items-center justify-center py-12 px-4">
        <div className="bg-white px-8 py-6 rounded-xl shadow-lg">

          <div>
            <h2 className="text-xl font-bold font-montserrat">Careers Application</h2>
            <div className="space-y-4 py-2">
              <p className="text-sm/6 opacity-70">
                Thank you for considering a career with CTO Labs. Please fill out the form below to apply for an open role or express your interest in future opportunities. We look forward to reviewing your application and will be in touch soon to confirm receipt.
              </p>
              <p className="text-sm/6 opacity-70">
                You are currently on the application page for the following role:
              </p>
            </div>
            <div className="relative flex items-center space-x-4 p-4 m-2 border-cto_secondary_dark_grey/20 border-2 rounded-lg shadow-md">
              <div className="min-w-0 flex-auto">
                <div className="flex items-center gap-x-3">
                  <div className={"text-green-400 bg-green-400/10 flex-none rounded-full p-1"}>
                    <div className="size-2 rounded-full bg-current" />
                  </div>
                  <h2 className="min-w-0 text-sm/6 font-semibold">
                    <a href={CareerSummary.href} className="flex gap-x-2">
                      <span className="truncate">{CareerSummary.companyName}</span>
                      <span className="text-gray-400">/</span>
                      <span className="whitespace-nowrap">{CareerSummary.JobTitle}</span>
                      <span className="absolute inset-0" />
                    </a>
                  </h2>
                </div>
                <div className="mt-3 text-xs/5 text-gray-400">
                  <p className="truncate">{CareerSummary.JobSummary}</p>
                  <div className="">
                    <div className="flex items-center gap-x-2.5 font-semibold">
                      <p className="whitespace-nowrap">{CareerSummary.JobDepartment}</p>
                      <svg viewBox="0 0 2 2" className="size-1 flex-none fill-gray-300">
                        <circle r={1} cx={1} cy={1} />
                      </svg>
                      <p className="whitespace-nowrap">{CareerSummary.JobEmploymentType}</p>
                      <svg viewBox="0 0 2 2" className="size-1 flex-none fill-gray-300">
                        <circle r={1} cx={1} cy={1} />
                      </svg>
                      <p className="whitespace-nowrap">{CareerSummary.JobLocation}</p>
                      <svg viewBox="0 0 2 2" className="size-1 flex-none fill-gray-300">
                        <circle r={1} cx={1} cy={1} />
                      </svg>
                      <p className="whitespace-nowrap">{CareerSummary.JobWorkStyle}</p>
                    </div>
                  </div>
                </div>
              </div>
              <Link
                href={absoluteUrl(CareerSummary.href)}
                target="_blank"
              >
                <button className={"rounded-full flex items-center bg-cto_primary_orange hover:bg-cto_primary_purple pl-6 pr-4 py-2 text-sm font-light text-white shadow-sm hover:opacity-80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cto_primary_purple"}>
                  View Role
                  <ChevronRightIcon
                    className="flex-shrink-0 h-5 w-5 text-white"
                    aria-hidden="true"
                  />
                </button>
              </Link>
            </div>
          </div>

          <form onSubmit={submitApplication} encType="multipart/form-data" >
            <fieldset id="careerApplicationFields">
              <div className="border-b border-cto_secondary_dark_grey/10 py-8">
                <div className="flex">
                  <p className="text-sm tracking-tight font-semibold text-cto_secondary_highlight_red pt-0.5 pr-4">1.</p>
                  <h2 className="text-md tracking-wide font-semibold">Personal Information</h2>
                </div>
                <p className="mt-1 text-sm/6 opacity-70">This is the information that we will use to contact you.</p>

                <div className="mt-6 grid grid-cols-1 gap-6 sm:grid-cols-6">
                  <div className="sm:col-span-3">
                    <label htmlFor="first-name" className="flex items-center space-x-2 text-sm/6">
                      <IdentificationIcon
                        className="flex-shrink-0 h-4 w-4"
                        aria-hidden="true"
                      />
                      <span>First name</span>
                    </label>
                    <div className="mt-2">
                      <input
                        id="first-name"
                        name="firstName"
                        type="text"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        maxLength={64}
                        autoComplete="given-name"
                        className="block w-full rounded-md bg-white px-3 py-1.5 border-cto_secondary_dark_grey/30 text-base placeholder:text-gray-400 focus:outline focus:outline-2 focus:outline-offset-2 focus:outline-cto_primary_purple focus:ring-0 focus:border-gray-300 sm:text-sm/6  disabled:bg-cto_secondary_dark_grey/10 disabled:cursor-not-allowed"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label htmlFor="last-name" className="block text-sm/6 font-medium">
                      Last name
                    </label>
                    <div className="mt-2">
                      <input
                        id="last-name"
                        name="lastName"
                        type="text"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        maxLength={64}
                        autoComplete="family-name"
                        className="block w-full rounded-md bg-white px-3 py-1.5 border-cto_secondary_dark_grey/30 text-base placeholder:text-gray-400 focus:outline focus:outline-2 focus:outline-offset-2 focus:outline-cto_primary_purple focus:ring-0 focus:border-gray-300 sm:text-sm/6 disabled:bg-cto_secondary_dark_grey/10 disabled:cursor-not-allowed"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-4">
                    <label htmlFor="mobile" className="flex items-center space-x-2 text-sm/6">
                      <PhoneIcon
                        className="flex-shrink-0 h-4 w-4"
                        aria-hidden="true"
                      />
                      <span>
                        Phone
                      </span>
                    </label>
                    <div className="mt-2">
                      <input
                        id="mobile"
                        name="phoneNumber"
                        type="tel"
                        inputMode="tel"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value.replace(/[^0-9()+-\s]/g, ''))}
                        maxLength={32}
                        className="block w-full rounded-md bg-white px-3 py-1.5 border-cto_secondary_dark_grey/30 text-base placeholder:text-gray-400 focus:outline focus:outline-2 focus:outline-offset-2 focus:outline-cto_primary_purple focus:ring-0 focus:border-gray-300 sm:text-sm/6 disabled:bg-cto_secondary_dark_grey/10 disabled:cursor-not-allowed"
                      />
                    </div>
                  </div>
                  
                  <div className="sm:col-span-4">
                    <label htmlFor="email" className="flex items-center space-x-2 text-sm/6">
                      <MailIcon
                        className="flex-shrink-0 h-4 w-4"
                        aria-hidden="true"
                      />
                      <span>
                        Email address
                      </span>
                    </label>
                    <div className="mt-2">
                      <input
                        id="email"
                        name="emailAddress"
                        type="email"
                        value={emailAddress}
                        onChange={(e) => setEmailAddress(e.target.value)}
                        maxLength={92}
                        autoComplete="email"
                        className="block w-full rounded-md bg-white px-3 py-1.5 border-cto_secondary_dark_grey/30 text-base placeholder:text-gray-400 focus:outline focus:outline-2 focus:outline-offset-2 focus:outline-cto_primary_purple focus:ring-0 focus:border-gray-300 sm:text-sm/6 disabled:bg-cto_secondary_dark_grey/10 disabled:cursor-not-allowed"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-6 sm:col-start-1 -mb-3">
                    <div className="space-y-5">
                      <div className="flex gap-3">
                        <div className="flex h-6 shrink-0 items-center">
                          <div className="group grid size-4 grid-cols-1">
                            <input
                              id="workingRights"
                              name="workingRights"
                              type="checkbox"
                              aria-describedby="workingRights-description"
                              checked={hasAustralianWorkingRights}
                              onChange={()=> setHasAustralianWorkingRights((value) => !value)}
                              className="col-start-1 row-start-1 ring-0 appearance-none rounded border border-cto_secondary_dark_grey/30 bg-white checked:border-cto_primary_purple checked:bg-cto_primary_purple indeterminate:border-cto_primary_purple indeterminate:bg-cto_primary_purple focus:outline focus:outline-2 focus:outline-offset-2 focus:outline-cto_primary_purple disabled:border-gray-300 forced-colors:appearance-auto focus:ring-0 focus:border-gray-300 text-cto_primary_purple disabled:bg-cto_secondary_dark_grey/10 disabled:cursor-not-allowed"
                            />
                            <svg
                              fill="none"
                              viewBox="0 0 14 14"
                              className="pointer-events-none col-start-1 row-start-1 size-3.5 self-center justify-self-center stroke-white group-has-[:disabled]:stroke-gray-950/25"
                            >
                              <path
                                d="M3 8L6 11L11 3.5"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="opacity-0 group-has-[:checked]:opacity-100"
                              />
                              <path
                                d="M3 7H11"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="opacity-0 group-has-[:indeterminate]:opacity-100"
                              />
                            </svg>
                          </div>
                        </div>
                        <div className="text-sm/6">
                          <label htmlFor="comments" className="font-medium">
                            I confirm that I am an Australian citizen, permanent resident, or hold a valid Australian work visa.
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  {hasAustralianWorkingRights
                    ? (<div className="sm:col-start-1 sm:col-span-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div className="sm:col-span-2">
                          <label htmlFor="city" className="flex items-center space-x-2 text-sm/6">
                            <LocationMarkerIcon
                              className="flex-shrink-0 h-4 w-4"
                              aria-hidden="true"
                            />
                            <span>
                              City
                            </span>
                          </label>
                          <div className="mt-2">
                            <input
                              id="city"
                              name="cityOfResidence"
                              type="text"
                              value={cityOfResidence}
                              autoComplete="address-level2"
                              onChange={(e) => setCityOfResidence(e.target.value)}
                              maxLength={64}
                              className="block w-full rounded-md bg-white px-3 py-1.5 border-cto_secondary_dark_grey/30 text-base placeholder:text-gray-400 focus:outline focus:outline-2 focus:outline-offset-2 focus:outline-cto_primary_purple focus:ring-0 focus:border-gray-300 sm:text-sm/6 disabled:bg-cto_secondary_dark_grey/10 disabled:cursor-not-allowed"
                            />
                          </div>
                        </div>
                        <div className="sm:col-span-2">
                          <label htmlFor="region" className="block text-sm/6 font-medium">
                            State / Territory
                          </label>
                          <div className="mt-2 grid grid-cols-1">
                            <select
                              id="region"
                              name="stateOfResidence"
                              onChange={(e) => setStateOfResidence(e.target.value)}
                              maxLength={64}
                              autoComplete="address-level1"
                              value={stateOfResidence}
                              className="col-start-1 row-start-1 w-full appearance-none rounded-md bg-white border-cto_secondary_dark_grey/30 py-1.5 pl-3 pr-8 text-base focus:outline focus:outline-2 focus:outline-offset-2 focus:outline-cto_primary_purple focus:ring-0 focus:border-gray-300 sm:text-sm/6 disabled:bg-cto_secondary_dark_grey/10 disabled:cursor-not-allowed"
                            >
                              {statesOfAustralia.map((state, index) => (
                                <option key={index} value={state}>
                                  {state}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>)

                    : (<div className="sm:col-span-4 sm:col-start-1">
                        <label htmlFor="country" className="flex items-center space-x-2 text-sm/6">
                          <GlobeIcon
                            className="flex-shrink-0 h-4 w-4"
                            aria-hidden="true"
                          />
                          <span className="flex">
                            Country of citizenship
                            <p className="pl-2 pt-1 text-xs opacity-80">(If the above checkbox is unchecked)</p>
                          </span>
                        </label>
                        <div className="mt-2">
                          <input
                            id="country"
                            name="countryOfCitizenship"
                            type="text"
                            value={country}
                            onChange={(e) => setCountry(e.target.value)}
                            maxLength={64}
                            className="block w-full rounded-md bg-white px-3 py-1.5 border-cto_secondary_dark_grey/30 text-base placeholder:text-gray-400 focus:outline focus:outline-2 focus:outline-offset-2 focus:outline-cto_primary_purple focus:ring-0 focus:border-gray-300 sm:text-sm/6 disabled:bg-cto_secondary_dark_grey/10 disabled:cursor-not-allowed"
                          />
                        </div>
                      </div>)
                  }
                </div>
              </div>

              <div className="py-8 border-b border-gray-900/10">
                <div className="flex">
                  <p className="text-sm tracking-tight font-semibold text-cto_secondary_highlight_red pt-0.5 pr-4">2.</p>
                  <h2 className="text-md tracking-wide font-semibold">Upload Resumé / CV</h2>
                </div>
                <p className="mt-1 text-sm/6 opacity-70">Tell us about you...</p>
                <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  <div className="col-span-full">
                    <label className="flex items-center space-x-2 text-sm/6">
                      <DocumentTextIcon
                        className="flex-shrink-0 h-4 w-4"
                        aria-hidden="true"
                      />
                      <span>
                        Resumé / CV
                      </span>
                    </label>
                    <p className="text-xs/6 opacity-70">
                      Please upload your CV in PDF (.pdf) format. Your personal information will be protected in accordance with our Privacy Policy, which you can read <Link className="underline text-cto_primary_purple" href={absoluteUrl("/privacy")}>here</Link>.
                    </p>
                    <div className="mt-2">
                      <FileUploadComponent 
                        updateFilesCb={(files) => {
                          files ? setUploadedFile(files[0]) : setUploadedFile(null)}
                        }
                        maxFileSizeInMegaBytes={5}
                        accept=".pdf"
                        disabled={disabled}
                      />
                    </div>
                  </div>

                  <div className="col-span-full">
                    <label htmlFor="personalSummary" className="flex items-center space-x-2 text-sm/6">
                      <UserCircleIcon
                        className="flex-shrink-0 h-4 w-4"
                        aria-hidden="true"
                      />
                      <span>
                        Personal Summary
                      </span>
                    </label>
                    <p className="text-xs/6 opacity-70">
                      Use this space to tell us a little more about yourself and why you are interested in this role / registering your interest in working with CTO Labs.
                    </p>
                    <div className="mt-2">
                      <textarea
                        id="personalSummary"
                        name="personalSummary"
                        rows={3}
                        value={personalSummary}
                        onChange={(e) => setPersonalSummary(e.target.value)}
                        maxLength={1920}
                        disabled={disabled}
                        className="block w-full rounded-md bg-white px-3 py-1.5 border-cto_secondary_dark_grey/30 text-xs focus:outline focus:outline-2 focus:outline-offset-2 focus:outline-cto_primary_purple focus:ring-0 focus:border-gray-300 sm:text-sm/6 disabled:bg-cto_secondary_dark_grey/10 disabled:cursor-not-allowed disabled:resize-none"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="pt-8">
                <div>
                  <h2 className="text-lg font-semibold">Thank you,</h2>
                  <div className="space-y-4 py-2">
                    <p className="text-sm/6 opacity-70">
                      We appreciate the time you took to complete this form and look forward to reading. You will receive a confirmation email once your application has been successfully submitted, and our team will be in touch within 5 business days with an initial response.
                    </p>
                  </div>
                </div>
                
                <div className="flex justify-end pt-6">
                  <button
                    type="submit"
                    className="rounded-full bg-cto_primary_orange hover:bg-cto_primary_purple px-6 py-2 text-sm tracking-wide text-white shadow-sm hover:opacity-80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cto_primary_purple disabled:bg-cto_secondary_dark_grey/80 disabled:cursor-not-allowed"
                  >
                    Submit Application
                  </button>
                </div>
              </div>
            </fieldset>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CareerApplication;
