import { absoluteUrl } from "../../lib/_helper";

const ListAllCareers = ({ blok, shared }) => {
  const careers = [...shared.careers];
  console.dir({a: shared.careers[0]})
  return (
    <div className="px-4 sm:px-6 lg:px-8 pb-10 text-cto_secondary_dark_grey">
      <div className="flex justify-center">
        <div className="max-w-7xl w-full overflow-x-auto shadow-md sm:rounded-lg">
          <div className="inline-block min-w-full align-middle">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-cto_secondary_light_warm_grey">
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-6">
                      Job Title
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold">
                      Location
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold">
                      Department
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold">
                      Employment Type
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {careers.map((career, i) => (
                    <tr className="transition-transform transform hover:scale-100 hover:translate-x-4 overflow-visible pl-4" key={i}>
                      <td className="whitespace-nowrap px-3 pl-6 py-4 text-sm font-medium text-cto_primary_purple"><a href={absoluteUrl(career.full_slug)}>{career.content.JobTitle}</a></td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm">{career.content.JobLocation.join(", ") ?? "<location>"}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm">{career.content.JobDepartment ?? "<department>"}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm">{career.content.JobEmploymentType ?? "<employmentType>"}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ListAllCareers;
