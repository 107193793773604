import React from "react";

const CareerListingTitle = ({title}) => {
  return (
    <div className="font-montserrat text-5xl md:text-7xl font-semibold">
      {title ?? "Career Listing Title"}
    </div>
  );
};

export default CareerListingTitle;
