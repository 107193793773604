import React from "react";
import CareerListingApplyButton from './CareerListingApplyButton';

const CareerListingSidePanelField = ({title, answer}) => { 
  return (
    <div className="py-2">
      <div className="text-sm font-bold text-cto_primary_orange">
        {title}:
      </div>
      <div className="text-sm text-cto_secondary_dark_grey">
        {answer}
      </div>
    </div>
  );
}


const CareerListingSidePanel = ({careerId, department, employmentType, location, workStyle, reportingTo, hiringManager}) => {
  return (
    <div className="">
      <div className="sticky top-0 p-6 bg-cto_secondary_light_warm_grey rounded-md shadow-lg space-y-4">
        <div>
          <CareerListingApplyButton careerId={careerId}/>
        </div>
        <div className="pb-2 border-b border-cto_secondary_dark_grey/10">
          <CareerListingSidePanelField title={"Department"} answer={department ?? "<department>"}/>
          <CareerListingSidePanelField title={"Employment Type"} answer={employmentType ?? "<employmentType>"}/>
          <CareerListingSidePanelField title={"Location"} answer={location ?? "<location>"}/>
          <CareerListingSidePanelField title={"Work Style"} answer={workStyle ?? "<workStyle>"}/>
        </div>
        <div>
          <div className="text-sm font-semibold">
            Meet the hiring manager
          </div>
          <img
            alt=""
            src={"https://www.ctolabs.com.au/_next/image?url=https%3A%2F%2Fa.storyblok.com%2Ff%2F140130%2F1067x1067%2Fe6ca07b06a%2Funtitled-design-1-edited.png&w=640&q=75"}
            className="my-2 flex size-32 items-center justify-center rounded-full bg-gray-400 ring-1 ring-white"
          />
          <div className="text-sm font-semibold">
            Mike Mengell
          </div>
        </div>
      </div>
    </div>
  );
};

export default CareerListingSidePanel;
