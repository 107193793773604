import { mergeClasses, absoluteUrl } from "../lib/_helper";
import swal from "sweetalert";
import { useEffect, useState } from 'react'
import EmailValidator from "email-validator";
import { CheckIcon, CheckCircleIcon, ChevronLeftIcon, ClipboardIcon } from '@heroicons/react/outline';

export const gicsSectors = [
  "Energy",
  "Materials",
  "Industrials",
  "Consumer Discretionary",
  "Consumer Staples",
  "Health Care",
  "Financials",
  "Information Technology",
  "Communication Services",
  "Utilities",
  "Real Estate"
];

export const transactionTypes = [
  "Acquisition",
  "Merger",
  "Separation",
  "Divestment",
  "Carve-out",
  "Integration",
  "Joint Venture",
  "Recapitalisation",
  "Restructuring",
  "Bankruptcy and Insolvency",
  "Management Buyout (MBO)",
  "Leveraged Buyout (LBO)"
];

const AppState = {
  EnterData: 'EnterData',
  SeeResults: 'SeeResults',
  FinalPage: 'FinalPage',
};

const Progress = ({state, setState}) => {
  const ProgressState = {
    Complete: 'complete',
    Current: 'current',
    Upcoming: 'upcoming',
  };

  const steps = [
    { id: "Step 1", stateRef: AppState.EnterData, name: 'Enter fields', status: (state == AppState.EnterData) ? ProgressState.Current : ProgressState.Complete },
    { id: "Step 2", stateRef: AppState.SeeResults, name: 'See Suggestions & Make Selection', status: (state == AppState.SeeResults) ? ProgressState.Current : (state == AppState.EnterData ? ProgressState.Upcoming : ProgressState.Complete) },
    { id: "Step 3", stateRef: AppState.FinalPage, name: 'Summary', status: (state == AppState.FinalPage) ? ProgressState.Current : ProgressState.Upcoming },
  ]
  return (
    <nav aria-label="Progress">
      <ol role="list" className="space-y-4 md:flex md:space-x-8 md:space-y-0">
        {steps.map((step) => (
          <li key={step.name} className="md:flex-1">
            {step.status === ProgressState.Complete && (
              <a
                className="group flex flex-col cursor-pointer border-l-4 border-cto_primary_purple py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4 hover:opacity-50"
                onClick={() => setState(step.stateRef)}
              >
                <span className="text-sm font-medium text-cto_primary_purple">{step.id}</span>
                <span className="text-sm font-medium">{step.name}</span>
              </a>
            )}
            {step.status === ProgressState.Current && (
              <a
                className="flex flex-col border-l-4 cursor-default border-cto_primary_orange animate-pulse py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4 hover:opacity-50"
                aria-current="step"
              >
                <span className="text-sm font-medium text-cto_primary_purple">{step.id}</span>
                <span className="text-sm font-medium">{step.name}</span>
              </a>
            )}
            {step.status === ProgressState.Upcoming && (
              <a
                className="group flex flex-col cursor-not-allowed border-l-4 border-gray-200 py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4 hover:opacity-50"
              >
                <span className="text-sm font-medium text-gray-500 group-hover:text-gray-700">{step.id}</span>
                <span className="text-sm font-medium">{step.name}</span>
              </a>
            )}
          </li>
        ))}
      </ol>
    </nav>
  )
}

const ProjectNameSuggestionCard = ({name, description, alternatives, isChecked, setChecked, disabled}) => {
  return (
    <li
      className={
        mergeClasses(
          "overflow-hidden rounded-xl border-2",
          isChecked ? " border-cto_primary_orange/50" : "border-gray-200",
          disabled ? "opacity-50" : ""
      )}
      onClick={() => {!disabled && setChecked()}}
    >
      <div className={
        mergeClasses(
          "flex justify-between gap-x-4 border-b border-gray-900/5 p-6",
          isChecked ? "bg-cto_primary_orange/25" : "bg-gray-50" 
        )}>
        <div className="text-sm font-medium leading-6 text-gray-900">Project {name}</div>
        <input
          checked={isChecked}
          onChange={setChecked}
          type="checkbox"
          disabled={disabled}
          className="h-6 w-6 rounded-full border-gray-300 border text-cto_primary_orange focus:ring-cto_primary_orange"
        />
      </div>
      <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
        <div className="x-4 py-3">
          <dd className="text-gray-700">{description}</dd>
        </div>
        {(alternatives && alternatives.length > 0) && (
          <div>
            <div className="x-4 pt-3">
              <dt className="text-gray-900 underline">International variants:</dt>
            </div>
            {alternatives.map((alt, index) => {
              return (
                <div key={index} className="flex justify-between x-4 py-3">
                  <dt className="text-gray-500">{alt.name_variation}</dt>
                  <dt className="text-gray-500">({alt.language})</dt>
                </div>
              )
            })}
          </div>
        )}
      </dl>
    </li>
  )
}

const NameGen = ({ blok, shared }) => {
  //Stage 1
  const defaultSectorText = "Pick Sector";
  const defaultTransactionTypeText = "Pick Transaction Type";

  const [appState, setAppState] = useState(AppState.EnterData);
  const [submissionInProgress, setSubmissionInProgress] = useState(false);
  const [loadingText, setLoadingText] = useState("Processing...")
  const loadingPhases = [
    "Sparking Creativity...",
    "Brewing Brilliant Ideas...",
    "Caffeinating...",
    "Igniting Inspiration...",
    "Aligning the Stars...",
    "Generating Genius...",
    "Polishing Pixels...",
    "Syncing Synapses...",
    "Manifesting Magic...",
    "Warming Up the Brain...",
    "Rewriting Reality...",
    "Sharpening the Mind...",
    "Finding the Missing Puzzle Piece...",
    "Spinning the Wheel of Fortune...",
    "Untangling Thoughts...",
    "Lighting the Fuse...",
    "Herding Cats...",
    "Balancing the Universe...",
    "Optimizing Awesomeness...",
    "Connecting the Dots...",
    "Lassoing the Moon...",
    "Stretching Creative Muscles...",
    "Downloading Epiphanies...",
    "Charging Good Vibes...",
    "Cooking Up a Storm...",
    "Whipping Up Wonders...",
    "Harnessing Brainwaves...",
    "Unlocking Potential...",
    "Rolling the Dice of Innovation...",
    "Focusing the Laser Beam...",
    "Gathering Lightning...",
    "Spinning Up Greatness...",
    "Calibrating Perfection...",
    "Teleporting Brilliance...",
    "Turning Ideas into Reality..."
  ];

  const [isAnonymousSubmission, setIsAnonymousSubmission] = useState(false);
  const [isNewsletterSubscription, setIsNewsletterSubscription] = useState(false);
  const [firmName, setFirmName] = useState("");
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [selectedGicsSector, setSelectedGicsSector] = useState(defaultSectorText);
  const [selectedTransactionType, setSelectedTransactionType] = useState(defaultTransactionTypeText);
  const [transactionCharacteristics, setTransactionCharacteristics] = useState("");

  let formComplete = true;
  formComplete &= (selectedGicsSector != defaultSectorText);
  formComplete &= (selectedTransactionType != defaultTransactionTypeText);
  formComplete &= (transactionCharacteristics != "");
  formComplete &= (firmName != "" || isAnonymousSubmission);

  useEffect(() => {
    if (isAnonymousSubmission) {
      setFirmName("")
      setUserName("")
      setUserEmail("")
      setIsNewsletterSubscription(false)
    }

  }, [isAnonymousSubmission]);

  useEffect(() => {
    let intervalId;

    if (submissionInProgress) {
      const usedIndexes = new Set();

      intervalId = setInterval(() => {
        // Get a random index that's not already been used
        if (usedIndexes.size === loadingPhases.length) {
          usedIndexes.clear(); // Reset if all phases are used
        }

        let randomIndex;
        do {
          randomIndex = Math.floor(Math.random() * loadingPhases.length);
        } while (usedIndexes.has(randomIndex));

        usedIndexes.add(randomIndex);
        setLoadingText(loadingPhases[randomIndex]);
      }, 1000); // Change text every second
    }

    return () => clearInterval(intervalId); // Cleanup when loading stops
  }, [submissionInProgress])

  const onDataSubmissionClick = () => {
    if (!formComplete) {
      swal(
        "Oops!", 
        "Please fill in all mandatory fields to proceed.", 
        "error"
      );
      return;
    }
    if (userEmail != "" && !EmailValidator.validate(userEmail)) {
      swal("Oops!", "Invalid Email Format", "error")
      return;
    }

    const formData = new FormData()
    formData.append("sector", selectedGicsSector)
    formData.append("transactionType", selectedTransactionType)
    formData.append("transactionCharacteristics", transactionCharacteristics)
    formData.append("firmName", firmName)
    formData.append("userName", userName)
    formData.append("userEmail", userEmail)
    formData.append("isNewsletterSubscription", isNewsletterSubscription)
    formData.append("isAnon", isAnonymousSubmission)

    setSubmissionInProgress(true);
    setLoadingText("Processing...")
    fetch(absoluteUrl("/api/nom-de-guerre"), {
      body: formData,
      method: "POST",
    })
    .then(async (response) => {
      const jsonResponse = await response.json()
      setGeneratedNames(jsonResponse.generatedNames.options)
      setAppState(AppState.SeeResults);
    })
    .catch((error) => {
      console.error({error})
      swal("Oops, something went wrong!", "If you're still interested in the content, please send an email to connect@ctolabs.com.au", "error")
    })
    .finally(() => {
      setSubmissionInProgress(false);
    })
  }

  //Stage 2
  const [generatedNames, setGeneratedNames] = useState([])
  const [selectedName, setSelectedName] = useState();

  const onNameSelectionClick = () => {
    if (!selectedName) {
      swal(
        "Oops!", 
        "Please select a name to proceed.", 
        "error"
      );
      return;
    }

    const formData = new FormData()
    formData.append("firmName", firmName)
    formData.append("selectedName", selectedName)
    formData.append("transactionType", selectedTransactionType)
    formData.append("sector", selectedGicsSector)
    formData.append("userName", userName ?? "")

    fetch(absoluteUrl("/api/nom-de-guerre/confirmSelection"), {
      body: formData,
      method: "POST",
    })
    .then(async (res) => {
      console.dir({res})
      console.log("Operation successful")
    })
    .catch((error) => {
      console.error({error})
      swal("Oops, something went wrong!", "There was an error when trying to save your submission...", "error")
    })
    .finally(() => {
    })

    requestChoiceDescription()

    setAppState(AppState.FinalPage)
  }

  const regenerateNames = () => {
    onDataSubmissionClick();
  }

  //Stage 3

  const [wasRecentlyCopied, setWasRecentlyCopied] = useState(false);
  const [choiceDescription, setChoiceDescription] = useState(false);

  useEffect(() => {
    if (wasRecentlyCopied) {
      setTimeout(() => setWasRecentlyCopied(false), 2000)
    }

  }, [wasRecentlyCopied])

  const handleCopy = (textToCopy) => { 
    navigator.clipboard.writeText(textToCopy).then(
      () => {
        swal({
          title: `'${textToCopy}' has been copied to your clipboard`,
          icon: "success",
          timer: 3000,
          showCancelButton: false,
          showConfirmButton: false
        });
      },
      (err) => {
        console.error(`Failed to copy text: ${textToCopy}. Error:`, err);
        swal("Oops!", "Your browser blocked the clipboard copy.", "error")
      }
    );
  };

  const shareToolPopUp = () => {
    const url = "https://name.ctolabs.com.au/";
    swal({
      text: `This tool can be shared with url ${url}`,
      button: {
        text: "Copy link!",
        closeModal: false,

      },
    }).then((value) => {
      console.dir({value})
      handleCopy(url)
    })
  }

  const requestChoiceDescription = () => {
    const formData = new FormData()
    formData.append("sector", selectedGicsSector)
    formData.append("transactionType", selectedTransactionType)
    formData.append("transactionCharacteristics", transactionCharacteristics)
    formData.append("selectedName", `Project ${selectedName}`)

    const briefDescription = generatedNames.find((gn) => gn.name == selectedName)?.description ?? ""
    formData.append("briefDescription", briefDescription)

    setSubmissionInProgress(true);
    fetch(absoluteUrl("/api/nom-de-guerre/getChoiceDescription"), {
      body: formData,
      method: "POST",
    })
    .then(async (response) => {
      const jsonResponse = await response.json()
      console.dir({jsonResponse})
      setChoiceDescription(jsonResponse.choiceDescription.description)
    })
    .catch((error) => {
      console.error({error})
      swal("Oops, something went wrong!", "If you're still interested in the content, please send an email to connect@ctolabs.com.au", "error")
    })
    .finally(() => {
      setSubmissionInProgress(false);
    })
  }
  

  return (
    <div className="bg-cto_secondary_navy_blue">
      <div className="max-w-7xl mx-auto p-4">
        <div className="shadow-lg space-y-6 p-8 bg-white rounded-xl">
          <div className="pb-4">
            <Progress state={appState} setState={(val) => setAppState(val)}/>
          </div>
          {appState == AppState.EnterData && (
            <>
              <div>
                <div className="block font-medium leading-6 text-cto_secondary_dark_grey mr-[20%]">
                  <div className={"space-y-4 md:space-y-0 md:flex md:justify-between md:space-x-4 max-w-[640px]"}>
                    <div className="w-[312px]">
                      <div className="label">
                        <span className="label-tex flex">Pick the industry sector*: {(selectedGicsSector != defaultSectorText) && <CheckCircleIcon className="h-5 w-5 mt-0.5 mx-2 text-green-600" aria-hidden="true" />}</span>
                      </div>
                      <div className="mt-2">
                        <select 
                          className={mergeClasses("border-gray-300 w-full px-4 py-2 placeholder:text-gray-400 text-cto_secondary_dark_grey rounded-md focus:ring-cto_primary_purple sm:text-sm sm:leading-6",
                            submissionInProgress ? "bg-gray-100" : ""
                          )}
                          onChange={(e) => setSelectedGicsSector(e.target.value)}
                          disabled={submissionInProgress}
                          value={selectedGicsSector}
                          >
                            <option disabled>{defaultSectorText}</option>
                            {gicsSectors.map((sector) => {return (<option key={sector}>{sector}</option>)})}
                        </select>
                      </div>
                    </div>
                    <div className="w-[312px]">
                      <div className="label">
                        <span className="label-text flex">Pick the type of transaction: {(selectedTransactionType != defaultTransactionTypeText) && <CheckCircleIcon className="h-5 w-5 mt-0.5 mx-2 text-green-600" aria-hidden="true" />}</span>
                      </div>
                      <div className="mt-2">
                        <select 
                          className={mergeClasses("border-gray-300 w-full px-4 py-2 placeholder:text-gray-400 text-cto_secondary_dark_grey rounded-md focus:ring-cto_primary_purple sm:text-sm sm:leading-6",
                            submissionInProgress ? "bg-gray-100" : ""
                          )}
                          onChange={(e) => setSelectedTransactionType(e.target.value)}
                          disabled={submissionInProgress}
                          value={selectedTransactionType}
                        >
                          <option disabled>{defaultTransactionTypeText}</option>
                          {transactionTypes.map((sector) => {return (<option key={sector}>{sector}</option>)})}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="block font-medium leading-6 text-cto_secondary_dark_grey mr-[20%] min-w-[312px]">
                  <div className="label">
                    <span className="label-text flex">Characteristics of the transaction: {(transactionCharacteristics.length > 0) && <CheckCircleIcon className="h-5 w-5 mt-0.5 mx-2 text-green-600" aria-hidden="true" />}</span>
                  </div>
                  <div className="mt-2">
                    <textarea
                      rows={3}
                      value={transactionCharacteristics}
                      disabled={submissionInProgress}
                      onChange={(e) => setTransactionCharacteristics(e.target.value)}
                      className={mergeClasses("block w-full max-w-[640px] rounded-md border-0 px-4 py-2 text-cto_secondary_dark_grey shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cto_primary_purple sm:text-sm sm:leading-6",
                        submissionInProgress ? "bg-gray-100" : ""
                      )}
                    />
                  </div>
                </div>
              </div>
              <div>
                <div className="block font-medium leading-6 text-cto_secondary_dark_grey mr-[20%]">

                  <div className="mb-4">
                    <div className="space-y-5">
                      <div className="flex gap-3">
                        <div className="flex h-6 shrink-0 items-center">
                          <div className="group grid size-4 grid-cols-1">
                            <input
                              id="isAnonymousSubmission"
                              name="isAnonymousSubmission"
                              type="checkbox"
                              aria-describedby="anon submission"
                              checked={isAnonymousSubmission}
                              onChange={()=> setIsAnonymousSubmission((value) => !value)}
                              className="col-start-1 row-start-1 ring-0 appearance-none rounded border border-cto_secondary_dark_grey/30 bg-white checked:border-cto_primary_purple checked:bg-cto_primary_purple indeterminate:border-cto_primary_purple indeterminate:bg-cto_primary_purple focus:outline focus:outline-2 focus:outline-offset-2 focus:outline-cto_primary_purple disabled:border-gray-300 forced-colors:appearance-auto focus:ring-0 focus:border-gray-300 text-cto_primary_purple disabled:bg-cto_secondary_dark_grey/10 disabled:cursor-not-allowed"
                            />
                            <svg
                              fill="none"
                              viewBox="0 0 14 14"
                              className="pointer-events-none col-start-1 row-start-1 size-3.5 self-center justify-self-center stroke-white group-has-[:disabled]:stroke-gray-950/25"
                            >
                              <path
                                d="M3 8L6 11L11 3.5"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="opacity-0 group-has-[:checked]:opacity-100"
                              />
                              <path
                                d="M3 7H11"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="opacity-0 group-has-[:indeterminate]:opacity-100"
                              />
                            </svg>
                          </div>
                        </div>
                        <div className="">
                          <label htmlFor="isAnonymousSubmission" className="label-text flex hover:underline select-none">
                            Anonymise my submission.
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <div className={"space-y-4 md:space-y-0 md:grid md:grid-cols-2 md:gap-4 max-w-[640px] pr-4"}>
                    <div className="w-[300px] col-span-2">
                      <div className="label">
                        <span className="label-text flex">Your company: {(firmName != "") && <CheckCircleIcon className="h-5 w-5 mt-0.5 mx-2 text-green-600" aria-hidden="true" />}</span>
                      </div>
                      <div className="mt-2">
                        <input
                          value={firmName}
                          disabled={submissionInProgress || isAnonymousSubmission}
                          onChange={(e) => setFirmName(e.target.value)}
                          className={mergeClasses("block rounded-md border-0 px-4 py-2 w-full max-w-[320px] text-cto_secondary_dark_grey shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cto_primary_purple sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:placeholder:text-gray-100")}
                          placeholder="CTO Labs"
                        />
                      </div>
                    </div>
                    <div className="w-[300px]">
                      <div className="label">
                        <span className="label-text flex z-0"><span className="z-0">Your name:</span><span className="label-text text-sm text-gray-500 mt-0.5 z-0">&nbsp; (optional)</span> {(userName != "") && <CheckCircleIcon className="h-5 w-5 mt-0.5 mx-2 text-green-600" aria-hidden="true" />}</span>
                      </div>
                      <div className="mt-2">
                        <input
                          value={userName}
                          disabled={submissionInProgress || isAnonymousSubmission}
                          onChange={(e) => {setUserName(e.target.value); console.log("boi")}}
                          className={mergeClasses("block rounded-md border-0 px-4 py-2 w-full max-w-[320px] text-cto_secondary_dark_grey shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cto_primary_purple sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:placeholder:text-gray-100")}
                          placeholder="John Appleseed"
                        />
                      </div>
                    </div>
                    <div className="w-[300px]">
                      <div className="label">
                        <span className="label-text flex z-0"><span className="z-0">Your email:</span><span className="label-text text-sm text-gray-500 mt-0.5 z-0">&nbsp; (optional)</span> {(userEmail != "") && <CheckCircleIcon className="h-5 w-5 mt-0.5 mx-2 text-green-600" aria-hidden="true" />}</span>
                      </div>
                      <div className="mt-2">
                        <input
                          value={userEmail}
                          disabled={submissionInProgress || isAnonymousSubmission}
                          onChange={(e) => setUserEmail(e.target.value)}
                          className={mergeClasses("block rounded-md border-0 px-4 py-2 w-full max-w-[320px] text-cto_secondary_dark_grey shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cto_primary_purple sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:placeholder:text-gray-100")}
                          placeholder="john.appleseed@gmail.com"
                        />
                      </div>
                    </div>
                    <div className="w-full col-span-2">
                      <div className="space-y-5">
                        <div className="flex gap-3">
                          <div className="flex h-6 shrink-0 items-center">
                            <div className="group grid size-4 grid-cols-1">
                              <input
                                id="newletterSubscription"
                                name="newletterSubscription"
                                type="checkbox"
                                aria-describedby="newsletter subscription"
                                disabled={submissionInProgress || isAnonymousSubmission}
                                checked={isNewsletterSubscription}
                                onChange={()=> setIsNewsletterSubscription((value) => !value)}
                                className="col-start-1 row-start-1 ring-0 appearance-none rounded border border-cto_secondary_dark_grey/30 bg-white checked:border-cto_primary_purple checked:bg-cto_primary_purple indeterminate:border-cto_primary_purple indeterminate:bg-cto_primary_purple focus:outline focus:outline-2 focus:outline-offset-2 focus:outline-cto_primary_purple disabled:border-gray-300 forced-colors:appearance-auto focus:ring-0 focus:border-gray-300 text-cto_primary_purple disabled:bg-cto_secondary_dark_grey/10 disabled:cursor-not-allowed"
                              />
                              <svg
                                fill="none"
                                viewBox="0 0 14 14"
                                className="pointer-events-none col-start-1 row-start-1 size-3.5 self-center justify-self-center stroke-white group-has-[:disabled]:stroke-gray-950/25"
                              >
                                <path
                                  d="M3 8L6 11L11 3.5"
                                  strokeWidth={2}
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="opacity-0 group-has-[:checked]:opacity-100"
                                />
                                <path
                                  d="M3 7H11"
                                  strokeWidth={2}
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="opacity-0 group-has-[:indeterminate]:opacity-100"
                                />
                              </svg>
                            </div>
                          </div>
                          <div className="">
                            <label htmlFor="newletterSubscription" className={mergeClasses("label-text flex select-none", (submissionInProgress || isAnonymousSubmission) ? "text-gray-500" : "hover:underline")}>
                              I would like to also sign-up to the CTO Labs newsletter.
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <button 
                  className={
                    mergeClasses(
                      "inline-flex text-white font-bold w-full items-center justify-center py-3 border border-transparent text-base rounded-full",
                      formComplete
                        ? "bg-cto_primary_orange hover:bg-cto_primary_purple cursor-pointer"
                        : "bg-cto_secondary_dark_grey hover:bg-opacity-50 cursor-not-allowed",
                      submissionInProgress
                        ? "animate-pulse"
                        : ""
                    )}
                  onClick={onDataSubmissionClick}
                  disabled={submissionInProgress}
                >
                  {submissionInProgress 
                    ? (<div className="flex">
                      <svg className="animate-spin -ml-1 mr-3 h-4 w-4 mt-1 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      {loadingText}
                    </div>)
                    : (<>Generate Names</>)
                  }
                </button>
              </div>
              <div>
                <span className="text-gray-500">*Industry sectors are based off of GICS, read more <a className="underline" target="_blank" href="https://en.wikipedia.org/wiki/Global_Industry_Classification_Standard">here</a>.</span>
                <br/>
                <span className="text-gray-500">See our privacy policy <a className="underline" target="_blank" href="https://www.ctolabs.com.au/privacy">here</a>.</span>
              </div>
            </>
          )}
          {appState == AppState.SeeResults && (
            <>
              <div className="p-4 lg:p-8">
                <ul role="list" className="grid grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-2 lg:grid-cols-3 xl:gap-x-8">
                  {generatedNames.map((option, index) => ( 
                    <ProjectNameSuggestionCard key={index} name={option.name} description={option.description} alternatives={option.language_variations} isChecked={option.name == selectedName} setChecked={() => setSelectedName(option.name)} disabled={submissionInProgress}/>
                  ))}
                </ul>
              </div>
              <div className="flex justify-between">
                <button 
                  className={
                    mergeClasses(
                      "inline-flex text-cto_primary_orange bg-white ring-cto_primary_orange ring-1 ring-inset font-bold max-w-[360px] w-4/5 items-center justify-center px-18 py-3 border border-transparent text-base rounded-full hover:ring-cto_primary_purple hover:text-cto_primary_purple cursor-pointer"
                    )}
                  onClick={regenerateNames}
                  disabled={submissionInProgress}
                >
                  Regenerate names
                </button>
                <button 
                  className={
                    mergeClasses(
                      "inline-flex text-white font-bold max-w-[360px] w-4/5 items-center justify-center px-18 py-3 border border-transparent text-base rounded-full ",
                      selectedName
                        ? "bg-cto_primary_orange hover:bg-cto_primary_purple cursor-pointer"
                        : "bg-cto_secondary_dark_grey hover:bg-opacity-50 cursor-not-allowed",
                    )}
                  onClick={onNameSelectionClick}
                  disabled={submissionInProgress}
                >
                  Select name
                </button>
              </div>
            </>
          )}
          {appState == AppState.FinalPage && (
            <>
              <div>
                <div className="w-full text-center mt-10 pb-6">
                  <div className="mx-auto">You selected...</div>
                </div>
                <div 
                  className="w-full text-center mb-10 hover:opacity-80 cursor-pointer group"
                  onClick={() => handleCopy(`Project ${selectedName}`)}
                >
                  <div className="text-7xl font-bold text-cto_primary_orange">Project</div>
                  <div className="flex justify-center align-bottom">
                    <div className="h-full mx-2 mt-auto mb-2 opacity-0">
                      <ClipboardIcon
                        className="flex-shrink-0 h-6 w-6 text-gray-400 group-hover:h-8 group-hover:w-8 transition-transform duration-200 ease-in-out"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="text-7xl font-bold text-cto_primary_orange">{selectedName}</div>
                    <div className="flex h-full mx-2 mt-auto mb-2">

                      <ClipboardIcon
                        className="flex-shrink-0 h-6 w-6 text-gray-400 group-hover:h-8 group-hover:w-8 group-hover:animate-bounce transition-transform duration-200 ease-in-out"
                        aria-hidden="true"
                      />
                      <div className="absolute transform text-sm ml-10 bg-gray-700 text-white px-2 py-1 rounded opacity-0 group-hover:opacity-100 transition-opacity">
                        {wasRecentlyCopied 
                          ? (<>Copied! ✅</>)
                          : (<>Copy to clipboard</>)
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full text-md text-center pb-4 border-b border-x-cto_secondary_dark_grey">
                {
                  choiceDescription ??
                  (<div className="p-5">
                    <div className="h-full">
                      <div className="flex justify-center space-x-4 animate-bounce h-10 w-full opacity-50">
                        <div className="w-6 h-4 bg-cto_primary_purple rounded-full"></div>
                        <div className="w-6 h-4 bg-cto_primary_purple rounded-full"></div>
                        <div className="w-6 h-4 bg-cto_primary_purple rounded-full"></div>
                      </div>
                    </div>
                  </div>)
                }
              </div>
              <div className="w-full italic tracking-wide text-center pb-4">
                {`We hope this tool has been valuable to you. If you'd like to discuss how we can assist with your current or future transactions, just click the request callback button below to connect with us.`}
              </div>
              <div className="flex justify-between">
                <button 
                  className={
                    mergeClasses(
                      "text-cto_primary_orange bg-white ring-cto_primary_orange ring-1 ring-inset font-bold max-w-[360px] w-4/5 items-center justify-center px-18 py-3 border border-transparent text-base rounded-full hover:ring-cto_primary_purple hover:text-cto_primary_purple cursor-pointer"
                    )}
                  onClick={() => setAppState(AppState.EnterData)}
                  disabled={submissionInProgress}
                >
                  Take me back to the beginning
                </button>
                <button 
                  className="text-white font-bold max-w-[360px] w-4/5 items-center justify-center px-18 py-3 border border-transparent text-base rounded-full bg-cto_primary_orange hover:bg-cto_primary_purple cursor-pointer"
                  onClick={() => shareToolPopUp()}
                >
                  Share this tool
                </button>

              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default NameGen;
