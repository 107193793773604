import React from "react";
import Link from "next/link";
import { sbEditable } from "@storyblok/storyblok-editable";

import { absoluteUrl } from "../../lib/_helper";
import CareerListingTitle from './CareerListingTitle';
import CareerListingSidePanel from './CareerListingSidePanel';
import CareerListingApplyButton from './CareerListingApplyButton';
import CareerListingDescription from './CareerListingDescription';

const CareerListing = ({ blok }) => {
  console.dir({dirblok: blok})
  return (
    <div {...sbEditable(blok)} className="bg-white flex text-cto_secondary_dark_grey">
      <div className="max-w-5xl w-full mx-auto items-center justify-center py-12">
        <div className="mx-4">
          <div className="md:grid md:grid-cols-5 space-x-4 mr-4">
            <div className="col-span-4 w-full space-y-8 pr-20 mb-4">
              <CareerListingTitle title={blok.JobTitle} />
            </div>
          </div>
          <div className="md:grid md:grid-cols-5 space-x-4 mr-4">
            <div className="col-span-4 w-full space-y-8 pr-20">
              <CareerListingDescription sections={blok.JobDescription} />
              <div className="border-b border-cto_secondary_dark_grey/10 pb-12">
                <div className="w-96 min-w-16 max-w-96 mx-auto">
                  <CareerListingApplyButton careerId={blok.id}/>
                </div>
              </div>
              <div className="pt-8">
                <div className="px-16 opacity-80 font-medium text-lg text-center">
                  Not quite right? <br/>
                  Have a look at our other open roles that might be better suited to your skillset.
                  <div className="w-96 mx-auto pt-4">
                    <Link
                      href={absoluteUrl("/about/careers")}
                      className="align-middle"
                    >
                      <button
                        type="submit"
                        className="rounded-full bg-white w-full px-6 py-4 text-md font-normal text-cto_primary_orange shadow-sm hover:opacity-80  border-2 focus:outline focus:outline-2 focus:outline-offset-2 focus:outline-cto_primary_orange"
                      >
                        See open roles
                      </button>
                    </Link>
                  </div>
                </div>

              </div>
              
            </div>
            <div className="col-span-1 w-full h-full overflow-visible sidebar">
              <aside className="">
                <CareerListingSidePanel careerId={blok.id} department={blok.JobDepartment} employmentType={blok.JobEmploymentType} location={blok.JobLocation.join(", ")} workStyle={blok.JobWorkStyle} hiringManager={null} />
              </aside>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CareerListing;
